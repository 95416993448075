<template>
<!--  会议资料管理-->
  <div style=" margin: -15px -15px;overflow: hidden">
    <a-spin :spinning="spinning">
      <div class="liveMeet-top">
        <div class="liveMeet-top-input">
          <div>状态:</div>
          <a-radio-group
              style="padding-left: 10px"
              v-model="radioValue"
              @change="onChange">
<!--            0:待收集材料 1:已完成 2:待打款-->
            <a-radio-button value="">
              全部
            </a-radio-button>
            <a-radio-button value="2">
              待打款
            </a-radio-button>
            <a-radio-button value="0">
              待收集材料
            </a-radio-button
            ><a-radio-button value="1">
            已完成
            </a-radio-button>
          </a-radio-group>
        </div>
        <div class="liveMeet-top-input">
          <div>会议名称:</div>
          <a-input
              @pressEnter="seachBtn"
              v-model="titleData"
              class="input"
              placeholder="请输入" />
        </div>
        <div class="liveMeet-top-input">
          <a-button
              @click="seachBtn"
              type="primary">查询</a-button>
          <a-button
              @click="resetBtn"
              style="margin-left: 10px">重置</a-button>
        </div>
      </div>
      <div class="activity-table">
<!--        只有内部账号可见-->
        <div v-if="typePerType === '1'" class="activity-table-btn">
          <a-button
              @click="addBtn('新增')"
              type="primary">
            新增
          </a-button>
        </div>
        <a-table
            style="width: 99%;margin: auto"
            :rowKey="record=>record.id"
            :pagination="false"
            :columns="columns"
            :data-source="data">
          <span slot="prizeTotal" slot-scope="text, record">
            <a v-if="record.state === 1"  @click="openDel(record)">查看明细</a>
            <a v-else @click="openDel(record)">查看/上传明细</a>
          </span>
          <span slot="system" slot-scope="text, record">
            <a @click="restBtn(record,'修改')">修改</a>
          </span>
          <span slot="reviewedCount" slot-scope="text, record">
            <a @click="openDel(record)">{{record.reviewedCount}}</a>
          </span>
          <span slot="state" slot-scope="text, record">
            <!--状态 0:待收集材料 1:已完成 2:待打款 -->
            <div v-if="text === 1"><admin_con style="color: #00A854;" class="icon" type="icon-yuandian1"/>已完成</div>
            <div v-if="text === 0"><admin_con style="color: #0E77D1;" class="icon" type="icon-yuandian1"/>收集材料</div>
            <div v-if="text === 2"><admin_con style="color: #D9001B;" class="icon" type="icon-yuandian1"/>待打款</div>
          </span>
        </a-table>
        <div style="width: 100%;height: 50px;">
          <div style="float: right;margin-right: 20px;margin-top: 15px">
            <a-pagination
                :current="pageNo"
                @change="paginationBtn"
                :total="tableCount" />
          </div>
        </div>
      </div>
      <!-------------------对话框----------------->
      <a-drawer
          :title="title"
          placement="right"
          :closable="false"
          :visible="visible"
          width="550px"
          @close="closeDrawer"
      >
        <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="会议名称" prop="name">
            <a-input style="width: 325px" placeholder="请输入" v-model="form.name"/>
          </a-form-model-item>
          <a-form-model-item label="会议时间" prop="time">
            <a-input style="width: 325px" placeholder="请输入" v-model="form.time"/>
          </a-form-model-item>
          <a-form-model-item label="项目代号" prop="code">
            <a-input style="width: 325px" placeholder="请输入" v-model="form.code"/>
          </a-form-model-item>
          <a-form-model-item label="资料负责人" prop="per">
<!--            mode="multiple"-->
            <a-select
                style="width: 325px"
                show-search
                option-filter-prop="children"
                v-model="form.per"
                placeholder="请选择">
              <a-select-option
                  v-for="select_o in leaderOperateList"
                  v-if="select_o.status !== 'INVALID'"
                  :value="select_o.id">
                {{select_o.userName}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="closeDrawer">
            取消
          </a-button>
          <a-button type="primary" @click="addSubmit">
            确认
          </a-button>
        </div>
      </a-drawer>
    </a-spin>
  </div>
</template>

<script>

import {DataManager, otherManageList, postOtherManageList, putOtherManageList} from "@/service/MedicalConference_y";

const columns = [
  {
    title: '项目代号',
    dataIndex: 'code',
    key: 'code',
    width: "15%"
  },{
    title: '会议名称',
    dataIndex: 'title',
    key: 'title',
    width:'30%',
  },
  {
    title: '会议时间',
    dataIndex: 'meetingDate',
    key: 'meetingDate',
    width:'15%',
    // scopedSlots: { customRender: 'meetingDate' },
  },
  {
    title: '待审核',
    dataIndex: 'reviewedCount',
    key: 'reviewedCount',
    ellipsis: true,
    width:'7%',
    scopedSlots: { customRender: 'reviewedCount' },
  },
  {
    title: '会议资料',
    dataIndex: 'prizeTotal',
    key: 'prizeTotal',
    ellipsis: true,
    width:'10%',
    scopedSlots: { customRender: 'prizeTotal' },
  },
  {
    title: '状态',
    dataIndex: 'state',
    key: 'state',
    ellipsis: true,
    width:'10%',
    scopedSlots: { customRender: 'state' },
  },
  {
    title: '操作',
    dataIndex: 'system',
    key: 'system',
    ellipsis: true,
    scopedSlots: { customRender: 'system' },
  },
];

const data = [];
import {admin_icon} from "@/utils/myIcon_f";
import {getCommunictionList} from "@/service/liveMeet_api";

export default {
  name: "DataApproval",
  components:{
    admin_con: admin_icon,
  },
  data(){
    return {
      pageNo:1,
      visible:false,
      spinning:false,
      radioValue:'',
      titleData:'',
      form: {
        name: '',
        time: '',
        code: '',
        per: undefined,
      },
      rules: {
        name: [{required: true, message: '请输入', trigger: 'blur'}],
        time: [{required: true, message: '请输入', trigger: 'blur'}],
        code: [{required: true, message: '请输入', trigger: 'blur'}],
        per: [{required: true, message: '请选择', trigger: 'change'}],
      },
      labelCol:{span:5},
      wrapperCol:{span:14},
      leaderOperateList:[],
      columns,
      data,
      tableCount:0,
      title:'新增',
      typePerType:null,
      listId:null,
    }
    },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"其他会议管理")
    const user_info = window.localStorage.getItem("user_info");
    this.typePerType = JSON.parse(user_info).type
  },
  mounted() {
    this.getList()
  },
  methods:{
    async getList() {
      let data = {
        title:this.titleData,
        page_no:this.pageNo,
        state:this.radioValue,
        page_size:10
      }
      const response = await otherManageList(data)
      if (response.code === 0) {
        this.data = response.data
        this.tableCount = response.count
      }else {
        this.$message.warning(response.message)
      }
    },
    closeDrawer(){
      this.form.name = ''
      this.form.code = ''
      this.form.time = ''
      this.form.per = undefined
      this.visible = false
    },
    addSubmit(){
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            let form = this.form
            if(this.title === '新增'){
              //1.获取本地存储内容
              const user_info = window.localStorage.getItem("user_info");
              const id =  JSON.parse(user_info).id
              let data = {
                "title": form.name,
                "code": form.code,
                "meetingDate": form.time,
                "createdById": id,
                "externalId":form.per
              }
              this.postList(data)
            }else {
              let data = {
                "id": this.listId,
                "title": form.name,
                "code": form.code,
                "meetingDate": form.time,
                "externalId":form.per
              }
              this.putList(data)
            }
            this.visible = false
            this.$refs.ruleForm.resetFields()
            this.form.name = ''
            this.form.code = ''
            this.form.time = ''
            this.form.per = undefined
          } else {
            return false;
          }
        });
    },
    //新增
    async postList(data) {
      const response = await postOtherManageList(data)
      if (response.code === 0) {
        this.$message.success('新增成功~')
        await this.getList()
      }else {
        this.$message.warning(response.message)
      }
    },
    //修改
    async putList(data) {
      const response = await putOtherManageList(data)
      if (response.code === 0) {
        this.$message.success('修改成功~')
        await this.getList()
      }else {
        this.$message.warning(response.message)
      }
    },
    // popupScrollPer(){
    //
    // },
    // selectInput(){
    //
    // },
    onChange(){
      this.getList()
    },
    seachBtn(){
      this.getList()
    },
    resetBtn(){
      this.pageNo = 1
      this.radioValue = ''
      this.titleData = ''
      this.getList()
    },
    addBtn(title){
      this.title = title
      this.getPerList()
      this.visible = true
    },
    paginationBtn(pageNo){
      this.pageNo = pageNo
      this.getList()
    },
    openDel(row){
      this.$router.push({path:'detailsMeetings',query:{id:row.id,createdById:row.createdById,externalId:row.externalId,state:row.state,type:'2',title:row.title,time:row.meetingDate}})
    },
    //获取公司人员列表
    async getPerList() {
      //销售部 8994250
      // const response = await getCommunictionList(1,9999,1,'')
      const response = await DataManager()
      if(response.code === 0){
        this.leaderOperateList = response.data
      }
    },
    restBtn(row,title){
      this.listId = row.id
      this.title = title
      this.getPerList()
      this.visible = true
      let form = this.form
      form.name = row.title
      form.code = row.code
      form.time = row.meetingDate
      form.per = (row.externalId)*1
    }
  }
}
</script>

<style scoped lang="scss">
.liveMeet-top{
  width: 100%;
  height: 85px;
  border-bottom: 15px solid #f0f2f5;
  display: flex;
  //justify-content: space-between;
  .liveMeet-top-input{
    display: flex;
    justify-content: center;
    width: 30%;
    height: 50px;
    line-height: 30px;
    padding-top: 20px;
    .input{
      width: 70%;
      margin-left: 10px;
    }
  }
}
.activity-table{
  width: 100%;
  min-height: 600px;
  .activity-table-btn{
    width: 100%;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .icon{
    font-size: 8px;
    margin-right: 5px;
  }
}
</style>